import CustomScroll from 'react-custom-scroll';
import {useModal} from "../context";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { useKeenSlider } from "keen-slider/react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "keen-slider/keen-slider.min.css"
import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import parse from 'html-react-parser';
import ReactPlayer from "react-player/lazy"

const desktop = window.matchMedia("(min-width: 1024px)").matches;

const projects = [
    {
        id: 1,
        title: 'Алгоритм',
        descr: 'Манипуляции, слежка, и мрачный киберпанк. Документальный сериал-исследование о том, как социальные сети влияют на миллиарды людей и проникают во все сферы нашей жизни',
        url: 'https://www.kinopoisk.ru/series/5265118/',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/algoritm/prev.jpg"),
        prevWebp: require("../assets/projects/algoritm/prev.webp"),
        prevMb: require("../assets/projects/algoritm/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/algoritm/prev-mb.webp"),
        video: require("../assets/projects/algoritm/video.mp4"),
        videoMb: require("../assets/projects/algoritm/video-mb.mp4"),
        special: require("../assets/projects/algoritm/special.mp4"),
        specialMb: require("../assets/projects/algoritm/special-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 2,
        title: 'Новое <br/>общество',
        descr: 'Экологичный транспорт, электромобили, новые источники энергии, переработка мусора и четырехдневная рабочая неделя — документальный сериал о том, каким будет наше будущее. Каждый из пяти эпизодов проекта «Новое общество» — исследование прорывных идей и технологий, которые уже стали реальностью и навсегда изменят наш мир.',
        url: 'https://www.kinopoisk.ru/series/5265111/',
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/no/prev.jpg"),
        prevWebp: require("../assets/projects/no/prev.webp"),
        prevMb: require("../assets/projects/no/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/no/prev-mb.webp"),
        video: require("../assets/projects/no/video.mp4"),
        videoMb: require("../assets/projects/no/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 3,
        title: 'Проблемы <br/>первого мира',
        descr: 'Расизм и BLM, культура отмены и кризис демократии, мигранты и национализм. Первый в России документальный сериал-исследование ключевых проблем современного мира в прорывном визуальном исполнении',
        url: 'https://www.kinopoisk.ru/series/5275411/',
        tags: ['сценарий', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/ppm/prev.jpg"),
        prevWebp: require("../assets/projects/ppm/prev.webp"),
        prevMb: require("../assets/projects/ppm/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/ppm/prev-mb.webp"),
        video: require("../assets/projects/ppm/video.mp4"),
        videoMb: require("../assets/projects/ppm/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 4,
        title: 'Русский <br/>форсаж',
        descr: 'Когда-то во Владивосток тайно попали первые в СССР японские иномарки. Потом возник самый большой авторынок России, а по местным улицам начали гонять первые дрифтеры. Документальный фильм о невероятной истории приморского дрифта.',
        url: null,
        tags: ['сценарий', 'съёмки', 'монтаж', 'дизайн', 'графика'],
        prev: require("../assets/projects/forsaj/prev.jpg"),
        prevWebp: require("../assets/projects/forsaj/prev.webp"),
        prevMb: require("../assets/projects/forsaj/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/forsaj/prev-mb.webp"),
        video: require("../assets/projects/forsaj/video.mp4"),
        videoMb: require("../assets/projects/forsaj/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 5,
        title: 'Алгоритм',
        descr: 'Кто решает, какую информацию мы потребляем каждый день? Проект о влиянии крупнейших социальных сетей на человечество',
        url: 'https://algorithm.lenta.ru',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/algoritm-spets/prev.jpg"),
        prevWebp: require("../assets/projects/algoritm-spets/prev.webp"),
        prevMb: require("../assets/projects/algoritm-spets/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/algoritm-spets/prev-mb.webp"),
        video: require("../assets/projects/algoritm-spets/video.mp4"),
        videoMb: require("../assets/projects/algoritm-spets/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 6,
        title: 'Время <br/>роста',
        descr: 'Вино, мебель и сыры. Проект о том, как развивается бизнес в России',
        url: 'https://time.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/time/prev.jpg"),
        prevWebp: require("../assets/projects/time/prev.webp"),
        prevMb: require("../assets/projects/time/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/time/prev-mb.webp"),
        video: require("../assets/projects/time/video.mp4"),
        videoMb: require("../assets/projects/time/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 7,
        title: 'Мировая <br/>история санкций',
        descr: 'От Кубы и Ирана до Северной Кореи. Проект о том, как живут и выживают страны под гнетом санкций',
        url: 'https://sanctions.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/sanctions/prev.jpg"),
        prevWebp: require("../assets/projects/sanctions/prev.webp"),
        prevMb: require("../assets/projects/sanctions/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/sanctions/prev-mb.webp"),
        video: require("../assets/projects/sanctions/video.mp4"),
        videoMb: require("../assets/projects/sanctions/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 8,
        title: 'Синдром отмены: <br/>Россия',
        descr: 'Чайковский, наука и уход Netflix. Проект об "отмене" русской культуры и ее последствиях для России и мира',
        url: 'https://otmena.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/otmena/prev.jpg"),
        prevWebp: require("../assets/projects/otmena/prev.webp"),
        prevMb: require("../assets/projects/otmena/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/otmena/prev-mb.webp"),
        video: require("../assets/projects/otmena/video.mp4"),
        videoMb: require("../assets/projects/otmena/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 9,
        title: 'Ударная <br/>волна',
        descr: 'Протесты, удар по экологии и угроза голода. Проект о последствиях антироссийских санкций для всего мира',
        url: 'https://wave.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/wave/prev.jpg"),
        prevWebp: require("../assets/projects/wave/prev.webp"),
        prevMb: require("../assets/projects/wave/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/wave/prev-mb.webp"),
        video: require("../assets/projects/wave/video.mp4"),
        videoMb: require("../assets/projects/wave/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    },
    {
        id: 10,
        title: 'Что делать?',
        descr: 'На чем сэкономить, куда вложить деньги и как победить стресс. Проект-инструкция о спокойной жизни в кризисных ситуациях',
        url: 'https://whattodo.lenta.ru/',
        tags: ['дизайн', 'разработка', 'контент'],
        prev: require("../assets/projects/whattodo/prev.jpg"),
        prevWebp: require("../assets/projects/whattodo/prev.webp"),
        prevMb: require("../assets/projects/whattodo/prev-mb.jpg"),
        prevMbWebp: require("../assets/projects/whattodo/prev-mb.webp"),
        video: require("../assets/projects/whattodo/video.mp4"),
        videoMb: require("../assets/projects/whattodo/video-mb.mp4"),
        logo: require("../assets/img/logo-media.svg").default,
        logoW: require("../assets/img/logo-media-wh.svg").default
    }
];

function Projects({loaded, redirect, setRedirect, setLogoCircleColor, setLogoTextColor, setLogoType}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [activePr, setActivePr] = useState(1);
    const [videos, setVideos] = useState({
        1: {
            loaded: false,
            play2: false,
            loaded2: false
        },
        2: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        3: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        4: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        5: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        6: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        7: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        8: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        9: {
            loaded: false,
            play2: false,
            loaded2: false,
        },
        10: {
            loaded: false,
            play2: false,
            loaded2: false,
        }
    });

    useLayoutEffect(() => {
        /*if(desktop){
            let animArrSt = [];

            document.querySelectorAll('.project').forEach(it => {
                const boxes = gsap.utils.toArray('.project__hd');

                boxes.forEach((box, i) => {
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: box,
                            scrub: true
                        }
                    }).fromTo(box, {
                        x: 10 - box.offsetLeft / 2,
                        y: -box.offsetTop,
                    },{
                        x: 0,
                        y: 0,
                    });
                });
            });

            return () => {
                animArrSt.forEach(it => {
                    it.kill();
                });
            };
        }*/
        setLogoType('media');
        setLogoTextColor('#000000');
    }, []);

    useEffect(()=>{
        if(loaded && !redirect && desktop){
            runAnim();
        }
    }, [loaded, redirect]);

    const transformLogo = (section) => {
        let logoText = '#fff';
        let logoCircle = '#70F9BB'

        if(!section.classList.contains('sc__projects-list')){
            logoText = '#000';
            setLogoType('media');
        }else {
            setLogoType('production');
        }

        setLogoCircleColor(logoCircle);
        setLogoTextColor(logoText);
    }

    useLayoutEffect(() => {
        if(loaded){
            let scStArr = [];

            let sections = document.querySelectorAll('.section');
            sections.forEach((section) => {
                let scStIt = ScrollTrigger.create({
                    trigger: section,
                    start: "top 40",
                    end: "bottom 40",
                    onEnter: () => {
                        transformLogo(section);
                    },
                    onEnterBack: () => {
                        transformLogo(section);
                    },
                });
                scStArr.push(scStIt);
            });

            return () => {
                scStArr.forEach(it => {
                    it.kill();
                });

                scStArr = [];
            };
        }
    }, [loaded]);

    useEffect(()=>{
        console.log(videos);
    }, [videos]);

    const runAnim = () => {
        const boxes = gsap.utils.toArray('.project');
        const overs = gsap.utils.toArray('.project__over');

        let animIt = gsap.timeline();

        boxes.forEach((box, i) => {
            animIt.fromTo(box.querySelector('.project__backdrop'), {
                opacity: 0,
            },{
                opacity: 1,
            });

            if(i < (boxes.length - 1)){
                animIt.fromTo('.project__over', {
                    y: '100%',
                },{
                    y: 0,
                    onComplete: function (){
                        if(i < (boxes.length - 1)){
                            setActivePr(i + 2);
                            if((i + 2) > 4){
                                setLogoType('media');
                            }else{
                                setLogoType('production');
                            }
                        }

                        setLogoTextColor('#fff');
                    }
                }).fromTo('.project__over', {
                    y: 0,
                },{
                    y: '-100%',
                    onReverseComplete: function (){
                        if(i > 0){
                            setActivePr(i);

                            if(i > 4){
                                setLogoType('media');
                            }else{
                                setLogoType('production');
                            }
                        }
                        setLogoTextColor('#fff');
                    }
                });
            }
        });

        let scTrAnim = ScrollTrigger.create({
            trigger: '.projects',
            start:"top top",
            animation: animIt,
            end:()=> '+=' + boxes.length * 100 + '%',
            pin: true,
            scrub: true,
            onEnter: () => {
                setLogoType('production');
                setLogoTextColor('#ffffff');
            }
        });

        return () => {
            scTrAnim.kill();
        };

    }

    return(
        <>
            <div className="sc__inner sc__projects-inner section">
                <div className="container">
                    <div className="sc__header">
                        <div className="sc__title">
                            Наши <br/>
                            проекты
                        </div>
                    </div>
                    <div className="form__before d-flex align-items-center justify-content-between">
                        <div onClick={()=>{
                            document.getElementById('projects').scrollIntoView({behavior: 'smooth'});
                        }} className="form__before-arrow">
                            <svg width="25" height="54" viewBox="0 0 25 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.4414 42.1094C19.6102 45.8245 13.8132 51.4458 12.8568 52.3732C12.7793 52.4484 12.6594 52.4451 12.5818 52.3698L2.00073 42.1094" stroke="#050505" strokeWidth="2.5" strokeLinecap="round"/>
                                <path d="M13.9695 1.99999C13.9695 1.30964 13.4099 0.749997 12.7195 0.75C12.0292 0.750003 11.4695 1.30965 11.4695 2.00001L13.9695 1.99999ZM13.9697 53L13.9695 1.99999L11.4695 2.00001L11.4697 53L13.9697 53Z" fill="#050505"/>
                            </svg>
                        </div>
                        <div className="sc__subtitle">
                            Узнайте больше о&nbsp;наших <br/>
                            проектах и&nbsp;насладитесь ими!
                        </div>
                    </div>
                </div>
            </div>
            <div className="sc__projects-list section">
                <div className="container">
                    <div id="projects" className="projects">
                        {projects.map((pr, i) => (
                            <>
                                <div className={'project project--' + pr.id + (videos[pr.id].loaded ? ' loaded' : '') + (activePr == pr.id ? ' active' : '')}>
                                    <a href={pr.url} target="_blank" className="project__bl">
                                        <div className="project__img">
                                            <picture>
                                                <source srcSet={pr.prevWebp}
                                                        type="image/webp"
                                                />
                                                <source srcSet={pr.prevMbWebp}
                                                        type="image/webp"
                                                        media="(max-width: 1023px)"
                                                />
                                                <source srcSet={pr.prev}
                                                        type="image/jpg"
                                                />
                                                <source srcSet={pr.prevMb}
                                                        type="image/jpg"
                                                        media="(max-width: 1023px)"
                                                />
                                                <img src={pr.prev}/>
                                            </picture>
                                        </div>
                                        <div className="project__video">
                                            {(!desktop || (desktop && (activePr == pr.id))) && (
                                                <ReactPlayer url={desktop ? pr.video : pr.videoMb} muted loop playsinline playing={loaded} onReady={()=>{
                                                    setVideos({
                                                        ...videos,
                                                        [pr.id]: {
                                                            ...videos[pr.id],
                                                            loaded: true
                                                        }
                                                    });
                                                }}/>
                                            )}
                                        </div>
                                        <div className="project__backdrop"/>
                                        <div className="project__ct d-flex align-items-center justify-content-center">
                                            <div className="project__logo mb-only">
                                                <img src={pr.logoW}/>
                                            </div>
                                            <div className="project__hd">
                                                <div className="project__title">
                                                    {parse(pr.title)}
                                                </div>
                                            </div>
                                            <div className="project__subtitle">
                                                {pr.descr}
                                            </div>
                                            <div className="project__tags d-flex">
                                                {pr.tags.map((tag, j) => (
                                                    <div className="project__tag text-uppercase d-flex align-items-center justify-content-center">
                                                        {tag}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="project__action mb-only">
                                                <a href={pr.url} target="_blank" className="btn__arrow btn__arrow--filled d-flex align-items-center justify-content-center">
                                                    <span>Смотреть</span>
                                                    <i className="icon__right">
                                                        <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                                            <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                                                        </svg>
                                                    </i>
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </>
                        ))}
                        {desktop && (
                            <div className="project__over"/>
                        )}
                    </div>
                </div>
            </div>
            <div className="sc__projects-after section">
                <div className="container">
                    <div className="d-flex justify-content-end">
                        <div onClick={()=>{
                            setRedirect(true);
                            setTimeout(()=>{
                                navigate('/');
                            }, 750);
                        }} className="btn__arrow d-flex align-items-center justify-content-center">
                            <span>На главную</span>
                            <i className="icon__right">
                                <svg width="91" height="36" viewBox="0 0 91 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M74 1.5C79.8232 7.50521 88.6892 16.6482 89.8687 17.8646C89.944 17.9422 89.9402 18.0617 89.865 18.1392L74 34.5" stroke="#050505" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M1.5 17.25C1.08579 17.25 0.75 17.5858 0.75 18C0.75 18.4142 1.08579 18.75 1.5 18.75V17.25ZM90 17.25H1.5V18.75H90V17.25Z" fill="#050505"/>
                                </svg>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects;
